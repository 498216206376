<template>
  <div class="component-wrapper">
    <b-card>
      <div class="d-flex">
        <div v-if="loader.agent" class="d-flex">
          <b-skeleton type="button" variant="danger" width="150px"></b-skeleton>
          <b-skeleton type="button" variant="danger" width="150px" class="ml-1"></b-skeleton>
          <b-skeleton type="button" variant="danger" width="150px" class="ml-1"></b-skeleton>
        </div>
        <b-button v-for="x in parameter.agentList" :key="x.code" :variant="parameter.selectedAgent == x ? `danger` : `outline-danger`" class="mr-1" @click="agentChanged(x)">
          {{ x.description }}
        </b-button>
        <b-button class="ml-auto" :disabled="!parameter.selectedAgent" variant="primary" @click.stop="showDetail()">
          <b-icon icon="bag-plus" scale="1"></b-icon> <span>New Order</span>
        </b-button>
      </div>
    </b-card>

    <b-card>
      <template #header>
        <span v-if="parameter.selectedAgent">
          Showing Work Order of
          <b>{{ parameter.selectedAgent.description }}</b>
        </span>

        <b-form inline class="ml-auto">
          <label class="mr-1">Month</label>
          <v-select
            v-model="parameter.selectedMonth"
            :options="parameter.monthList"
            label="name"
            value="value"
            style="min-width: 150px"
            placeholder="Select Month"
            :clearable="false"
            @option:selected="loadTable"
          ></v-select>
          <label class="mx-1">Year</label>
          <v-select
            v-model="parameter.selectedYear"
            :options="parameter.yearList"
            placeholder="Select Year"
            :clearable="false"
            style="min-width: 150px"
            @option:selected="loadTable"
          ></v-select>
        </b-form>
      </template>

      <template #default>
        <vue-element-loading :active="loader.table" />
        <b-table
          ref="orderTable"
          :items="filteredTable"
          :fields="table.fields"
          :current-page="table.paging.pageNo"
          :per-page="table.paging.pageSize"
          :total-rows="filteredTable.length"
          sort-by="orderDate"
          :busy="loader.table"
          small
          show-empty
          responsive
          bordered
          striped
        >
          <template #empty>
            <div class="d-flex">
              <h4 class="ml-auto mr-auto mt-2 mb-2 text-danger">{{ randomEmptyText }}</h4>
            </div>
          </template>
          <template #top-row>
            <td>
              <b-input v-model="table.filter.orderNo" placeholder="Filter Order No" debounce="300"></b-input>
            </td>
            <td>
              <b-input v-model="table.filter.client" placeholder="Filter Client" debounce="300"></b-input>
            </td>
            <td>
              <v-select
                v-model="table.filter.productTypeCode"
                :options="parameter.productTypeList"
                label="description"
                value="code"
                placeholder="Filter Product Type"
                :reduce="x => x.code"
                @option:selected="loadTable"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="table.filter.productCode"
                :options="parameter.productList"
                label="description"
                value="code"
                :reduce="x => x.code"
                placeholder="Filter Product"
                @option:selected="loadTable"
              ></v-select>
            </td>
            <td></td>
            <td></td>
          </template>
          <template #cell(orderDate)="data">
            <div class="d-flex">
              <div>
                <div>{{ data.item.orderDate | date }}</div>
                <div>
                  <small class="text-primary">{{ data.item.orderNo }}</small>
                </div>
              </div>
              <div v-if="data.item.notes" class="ml-auto">
                <b-icon :id="data.item.orderNo" icon="newspaper" variant="warning" class="ml-auto cursor-pointer"></b-icon>
                <b-popover :target="data.item.orderNo" variant="warning" triggers="hover" placement="top">
                  <template #title>Notes</template>
                  {{ data.item.notes }}
                </b-popover>
              </div>
            </div>
          </template>
          <template #cell(client)="data">
            <div>{{ data.item.contactName }}</div>
            <div>{{ data.item.brandName }}</div>
          </template>
          <template #cell(productDesc)="data">
            <div class="d-flex">
              <div>{{ data.item.productDesc }}</div>
              <span v-if="data.item.productTypeCode == 1" class="ml-auto text-primary cursor-pointer" @click.stop="showUrl(data.item.orderNo)">url</span>
            </div>

            <div>
              <div v-if="data.item.reportLoader">
                <b-icon icon="three-dots" animation="cylon"></b-icon>
              </div>
              <div v-else-if="data.item.driveUrl">
                <b-link :href="data.item.driveUrl" target="_blank">Report Link</b-link>
                <b-icon title="Delete Report" icon="trash" variant="danger" class="ml-1 cursor-pointer" @click="removeReport(data.item)"></b-icon>
              </div>
              <b-link v-else class="text-danger" @click.stop="createReport(data.item)">Create Report Link</b-link>
            </div>
          </template>
          <template #cell(dueDate)="data">
            <div v-if="data.item.dueDate">{{ data.item.dueDate | date }}</div>
            <small v-if="data.item.isExtended" class="text-success"><i>extended</i></small>
          </template>
          <template #cell(action)="data">
            <b-button v-if="data.item.productTypeCode === 1 && data.item.extendedTo == null" size="sm" variant="success" @click.stop="extend(data.item)">Extend</b-button>
            <b-button size="sm" variant="warning" class="ml-1" @click.stop="showDetail(data.item)">Edit</b-button>
            <b-button size="sm" variant="danger" class="ml-1" @click.stop="remove(data.item.orderNo)">Delete</b-button>
          </template>
        </b-table>
      </template>

      <template #footer>
        <div class="d-flex mt-auto align-items-center">
          <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="filteredTable.length" />
          <b-pagination v-model="table.paging.pageNo" :totalRows="filteredTable.length" :per-page="table.paging.pageSize" align="center" class="ml-auto"></b-pagination>
        </div>
      </template>
    </b-card>

    <b-modal
      v-if="parameter.selectedAgent"
      v-model="modal.detail"
      size="xl"
      :title="modalTitle"
      lazy
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      scrollable
      @hide="selectedOrder = null"
    >
      <work-order-detail :order="selectedOrder" :agent="parameter.selectedAgent" @onSaveSuccess="saveSuccess" @close="modal.detail = false"></work-order-detail>
    </b-modal>

    <b-modal v-model="modal.url" title="Target URL" size="lg" centered lazy hide-footer>
      <div v-for="x in targetUrlList" :key="x">
        {{ x }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import WorkOrderDetail from "./Detail.vue";

export default {
  name: `WorkorderList`,
  components: { WorkOrderDetail },
  data() {
    return {
      selectedOrder: null,
      parameter: {
        agentList: [],
        selectedAgent: null,
        monthList: [
          {
            name: "January",
            value: 1,
          },
          {
            name: "February",
            value: 2,
          },
          {
            name: "March",
            value: 3,
          },
          {
            name: "April",
            value: 4,
          },
          {
            name: "May",
            value: 5,
          },
          {
            name: "June",
            value: 6,
          },
          {
            name: "July",
            value: 7,
          },
          {
            name: "August",
            value: 8,
          },
          {
            name: "September",
            value: 9,
          },
          {
            name: "October",
            value: 10,
          },
          {
            name: "November",
            value: 11,
          },
          {
            name: "December",
            value: 12,
          },
        ],
        selectedMonth: null,
        yearList: [],
        selectedYear: null,
        productTypeList: [],
        productList: [],
        emptyTextList: [
          "Just hit 'order' and let the magic begin. You've got this!",
          "First order: it's like a first date. Just show up and be awesome!",
          "Dont worry, the first order is like riding a bike—except the bike is a computer and the ride is epic success!",
          "Place that first order—it's easier than deciding what to watch on Netflix!",
          "Starting your first order is like hitting the snooze button on your worries. Go for it!",
          "Think of the first order as a baby step to world domination. Ready, set, order!",
          "The first order is like the first pancake—just go for it and it’ll get easier from there!",
          "Press ‘order’ like it’s a magic button. Success is just a click away!",
          "First order is your ticket to the success party—grab it and join the fun!",
          "Take a deep breath, hit ‘order,’ and watch the magic happen. Easy peasy!",
        ],
      },
      table: {
        items: [],
        fields: [
          { key: "orderDate", label: "Work Order", sortable: true, stickyColumn: true, sortDirection: "desc" },
          { key: "client", label: "Client" },
          { key: "productTypeDesc", label: "Product Type", sortable: true },
          { key: "productDesc", label: "Product", sortKey: "productDesc", sortable: true },
          { key: "dueDate", label: "Due Date", class: "text-right" },
          { key: "action", label: "", class: "text-right" },
        ],
        filter: {
          orderNo: null,
          client: null,
          productType: null,
          productCode: null,
        },
        paging: {
          pageNo: 1,
          pageSize: 10,
        },
      },
      targetUrlList: [],
      loader: { table: false, agent: false },
      modal: { detail: false, url: false },
    };
  },
  computed: {
    randomEmptyText() {
      return this.parameter.emptyTextList[Math.floor(Math.random() * this.parameter.emptyTextList.length)];
    },
    modalTitle() {
      return `${this.parameter.selectedOrder ? "Edit" : "New"} Order for ${this.parameter.selectedAgent.description}`;
    },
    filteredTable() {
      let retVal = this.table.items;
      if (!!this.table.filter.orderNo) {
        const orderNoFilter = this.table.filter.orderNo.toLowerCase();
        retVal = retVal.filter(x => x.orderNo.toLowerCase().includes(orderNoFilter));
      }
      if (!!this.table.filter.client) {
        const clientFilter = this.table.filter.client.toLowerCase();
        retVal = retVal.filter(x => x.contactName.toLowerCase().includes(clientFilter) || x.brandName.toLowerCase().includes(clientFilter));
      }
      if (!!this.table.filter.productTypeCode) retVal = retVal.filter(x => x.productTypeCode == this.table.filter.productTypeCode);
      if (!!this.table.filter.productCode) retVal = retVal.filter(x => x.productCode == this.table.filter.productCode);
      return retVal;
    },
  },
  async mounted() {
    this.initYear();
    this.initMonth();
    this.loadProductType();
    await this.loadAgent();
    await this.loadProduct();
    await this.loadTable();
  },
  methods: {
    async loadProductType() {
      this.parameter.productTypeList = await api.get(`product/producttype`);
    },
    async loadAgent() {
      this.loader.agent = true;
      try {
        this.parameter.agentList = await api.get(`agent`);
        this.parameter.selectedAgent = this.parameter.agentList[0];
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.agent = false;
      }
    },
    async loadProduct() {
      this.parameter.productList = await api.get(`product/agentproduct`, {
        agentCode: this.parameter.selectedAgent.code,
      });
    },
    initYear() {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2020; i--) {
        this.parameter.yearList.push(i);
      }
      this.parameter.selectedYear = currentYear;
    },
    initMonth() {
      let currentMonth = new Date().getMonth() + 1;
      this.parameter.selectedMonth = this.parameter.monthList.find(x => x.value == currentMonth);
    },
    agentChanged(agent) {
      if (this.parameter.selectedAgent == agent) return;
      this.parameter.selectedAgent = agent;
      this.loadTable();
      this.loadProduct();
    },
    async loadTable() {
      this.loader.table = true;
      try {
        this.table.items = await api.get(`order`, {
          agentCode: this.parameter.selectedAgent.code,
          month: this.parameter.selectedMonth.value,
          year: this.parameter.selectedYear,
        });
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.table = false;
      }
    },
    extend(order) {
      let copyOrder = { ...order };
      copyOrder.isExtend = true;
      this.showDetail(copyOrder);
    },
    showDetail(order) {
      this.selectedOrder = order;
      this.modal.detail = true;
    },
    saveSuccess() {
      this.showToast(`success`, `Order saved successfully`);
      this.loadTable();
      this.modal.detail = false;
    },
    async createReport(order) {
      order.reportLoader = true;
      this.$refs.orderTable.refresh();
      try {
        await api.post(`order/report`, null, { orderNo: order.orderNo });
        this.showToast(`success`, `Report Created`);
        this.loadTable();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        order.reportLoader = false;
        this.$refs.orderTable.refresh();
      }
    },
    async remove(orderNo) {
      let confirmed = await this.showConfirm(`Are you sure you want to delete this order?`);
      if (!confirmed) return;
      try {
        await api.delete(`order/${orderNo}`);
        this.loadTable();
        this.showToast(`success`, `Order deleted successfully`);
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
    async removeReport(order) {
      let confirmed = await this.showConfirm(`Are you sure you want to delete this order's report ?`);
      if (!confirmed) return;
      try {
        await api.delete(`order/report/${order.orderNo}`);
        this.loadTable();
        this.showToast(`success`, `Order Report deleted successfully`);
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
    async showUrl(orderNo) {
      this.targetUrlList = await api.get(`order/target-urls/${orderNo}`);
      this.modal.url = true;
    },
  },
};
</script>
